import React from "react";
import axios from "axios";
import Layout from "../common/layouts";
import './book.css'

const Book = ({location}) => {
  const [book, setBook] = React.useState([]);
  const [bookID, setBookID] = React.useState("EIhp9yPBS83G0A1kNgvf")
  const [bookTitle, setBookTitle] = React.useState("")
  const [loaded, setLoaded] = React.useState(false);
  const [chapter, setChapter] = React.useState({})


  const setChapterByChapterNumber = (num) => {
    for (let index = 0; index < book.length; index++) {
      if (num === book[index].chapterNumber) {
        setChapter(book[index])
      }
    }
  }


  if (!loaded) {
    const params = new URLSearchParams(location.search);
    axios.post(
      'https://us-central1-akusmatika-31aec.cloudfunctions.net/api/v1/getBook',
      {
        bookId: params.get("bookID")
      }
    ).then((res) => {
      console.log("======================")
      console.log(res.data)
      setBook(res.data);
      setBookID(params.get("bookID"));
      setBookTitle(params.get("bookTitle"))
      setChapter(res.data[0])
      setLoaded(true);
    });
  }

  if (loaded) {
    return (
      <Layout>
        <div className={"container mx-auto"}>
          <h1>{bookTitle}</h1>
          <div className="book-container article__container bg-white-10">
            <div className="book-sidebar bg-white pt-6">
              <div className="book-sidebar-content bg-white">
                <h1 className={"text-orange-500"}>ÍNDICE</h1>
                  {book.map(chapter =>
                    (<li key={chapter.chapterNumber} className="pb-4 pt-4 pl-4 content-center hover:text-orange-600"
                         onClick={() => setChapterByChapterNumber(chapter.chapterNumber)}>
                      <div className="grid grid-flow-cols grid-cols-12 align-left">
                        <div className={"col-span-1"}>
                          {chapter.chapterNumber}
                        </div>
                        <div className={"col-span-11"}>
                          {chapter.title}
                        </div>
                      </div>
                    </li>)
                  )}
              </div>
            </div>

            <div className="book-content container mx-auto bg-white">
              <div className="grid grid-cols-5 align-middle">
                <div className={"col-span-4"}>
                  <h1>{chapter.chapterNumber} | {chapter.title}</h1>
                </div>
                <div className={"col-span-1"}>
                  <a href={chapter.pdfURL} className="pill">Descargar PDF</a>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{__html: chapter.excerpt}}/>
            </div>

          </div>
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout />
    )
  }
}




export default Book
